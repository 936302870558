import React from "react";
import "./LandingImage.css";

const LandingImage = ({ cover }) => {
  return (
    <div>
      <div className="cover_img_cont">
        <img src={cover} alt="images" className="cover-img" />
      </div>
    </div>
  );
};

export default LandingImage;
