import React from "react";
import "../Nurse Training/nurse.css";

const ParamedicalCourse = () => {
  return (
    <>
      <section className="courseoffer">
        <div className="container">
          <h1 style={{ paddingTop: "0" }}>Paramedical Courses</h1>

          <p className="sos_para pt-4" style={{ textAlign: "center" }}>
            SOS Children’s Villages Institute of Allied Health Science is a
            part/project of SOS Nursing School Faridabad & Affiliated from
            Bharat Sevak Samaj Channai Paramedical services are those services
            where professionals help the doctors in specialized areas and
            facilities for better diagnosis, treatment and therapy. The
            increasing demand of skilled para-medical professionals has opened
            up several career opportunities for young aspirants in India as well
            as abroad.
          </p>
        </div>
      </section>
    </>
  );
};

export default ParamedicalCourse;
