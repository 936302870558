import React from 'react'
import AboutInstitution from './Aboutinstitue/AboutInstitution'
import Branches from './branches/Branches'
import Footer from './footer/Footer'
import Home from './Home/Home'
import Infra from './infra/Infra'
import Navbar from './navbar/Navbar'
import PhotoGallery from './PhotoGalleryslider/PhotoGallery'
import Workshop from './workshop/Workshop'

const LandingPage = () => {
  return (
    <>
      <Navbar/>
      <Home/>
      <AboutInstitution/>
      <Infra/>
      <PhotoGallery/>
      
      <Footer/>
    </>
  )
}

export default LandingPage
