import React from 'react'
import Footer from '../footer/Footer'
import Navbar from '../navbar/Navbar'


import Pagecontact from './Pagecontact'

const Pagecontacthome = () => {
  return (
    <>

      <Navbar/>
      
      <Pagecontact/>
     <div  className='' style={{background: "#F8F9F9", marginTop:"1.5rem"}}>

      <Footer/>
    
     </div>
    </>
  )
}

export default Pagecontacthome
