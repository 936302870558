import React, { useState } from "react";
import "./sidebar.css";
import { Link } from "react-router-dom";

import { BsChevronRight } from "react-icons/bs";
const Sidebar = () => {
  const [isSideNav, setIsSideNav] = useState(false);

  const [open, setOpen] = useState(false);
  const showData = (state) => {
    setOpen(!state);
  };
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const showData1 = (state) => {
    setOpen1(!state);
  };
  const showData2 = (state) => {
    setOpen2(!state);
  };
  return (
    <>
      <div className="sidebar_main">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>

          <li>
            <div
              className="abt-btn"
              onClick={(e) => {
                showData(open);
              }}
            >
              About Us
              <span className="fas fa-caret-down first"> </span>
            </div>

            {open && (
              <ul className="abt-show">
                <li>
                  <Link to="/about">Message from Leadership</Link>
                </li>
                <li>
                  <Link to="/">
                    Know More About SOS Children Village of India
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li>
            <div
              onClick={(e) => {
                showData1(open1);
              }}
              className="vt-btn"
            >
              Courses
              <span className="fas fa-caret-down second"> </span>
            </div>

            {open1 && (
              <ul className="vt-show">
                <li>
                  <Link to="/nursingcourses">Nursing Courses</Link>
                </li>
                <li>
                  <Link to="/paramedicalcourse">Paramedical Courses</Link>
                </li>
              </ul>
            )}
          </li>

          <li>
            <div
              onClick={(e) => {
                showData2(open2);
              }}
              className="vt-btn"
            >
              Activites
              <span className="fas fa-caret-down second"> </span>
            </div>

            {open2 && (
              <ul className="vt-show">
                <li>
                  <Link to="/academics">Academic Activites</Link>
                </li>
                <li>
                  <Link to="/extra">Extracurricular Activites</Link>
                </li>
              </ul>
            )}
          </li>
          <li>
            <Link to="/">Faculty</Link>
          </li>
          <li>
            <Link to="/contactus">Contact Us</Link>
          </li>
          <li>
            <Link to="/login">Parents Login</Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
