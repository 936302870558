import React, { useState } from "react";
import "./infra.css";

const InfraChild = ({ e, ind }) => {
  const [extend, setExtend] = useState(true);
  return (
    <div className="col-lg-4" key={ind}>
      <div class="card">
        <img
          class="card-img-top"
          style={{ height: "30vh" }}
          src={e.img}
          alt="Card image cap"
        />
        <div class="card-body">
          <h5 class="card-title">{e.title}</h5>
          {extend ? (
            <p class="card-text">{e.desc.slice(0, 181)}..</p>
          ) : (
            <p class="card-text">{e.desc}</p>
          )}

          <p style={{ color: "#009EE0" }} onClick={() => setExtend(!extend)}>
            Know More
          </p>
        </div>
      </div>
    </div>
  );
};

export default InfraChild;
