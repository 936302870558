import Add from "../../images/Infraimg/extraCurricular.jpeg";
import classroom from "../../images/Infraimg/physical.jpeg";
import internet from "../../images/Infraimg/welfare.jpeg";
import labs from "../../images/Infraimg/clinical.jpg";
import safety from "../../images/Infraimg/community.jpg";

import first from "../../images/classroomimg/class/1.jpeg";
import second from "../../images/classroomimg/class/2.jpeg";
import third from "../../images/classroomimg/class/3.jpeg";
import fourth from "../../images/classroomimg/class/4.jpeg";

import firstlab from "../../images/classroomimg/Labs/1.jpeg";

import secondlab from "../../images/classroomimg/Labs/2.jpg";

import thirdlab from "../../images/classroomimg/Labs/3.jpeg";

import fourthlab from "../../images/classroomimg/Labs/4.jpeg";
import fifthlab from "../../images/classroomimg/Labs/5.jpg";
import sixlab from "../../images/classroomimg/Labs/6.jpeg";
// import sevenlab from "../../images/classroomimg/Labs/7.jpg";

import firstsafety from "../../images/classroomimg/safety/1.jpg";
// import secondsafety from "../../images/classroomimg/safety/2.JPG";
import thirdsafety from "../../images/classroomimg/safety/3.jpeg";
import fourthsafety from "../../images/classroomimg/safety/4.jpeg";
import fifthsafety from "../../images/classroomimg/safety/5.jpg";

import firstint from "../../images/classroomimg/internet/ex1.jpeg";
import secondint from "../../images/classroomimg/internet/ex2.jpeg";
import thirdint from "../../images/classroomimg/internet/ex3.jpeg";
import fourthint from "../../images/classroomimg/internet/ex4.jpeg";
import fifthint from "../../images/classroomimg/internet/ex5.jpeg";

import firstother from "../../images/classroomimg/other/1.jpg";
import secondother from "../../images/classroomimg/other/2.jpg";
// import thirdother from "../../images/classroomimg/other/3.JPG";
import fourthother from "../../images/classroomimg/other/4.jpeg";
const infraCardData = [
  {
    img: classroom,
    title: "Physical Facilities",
    desc: `The school is situated in a pollution free, eco friendly environment in about 4 acres land along with a hostel in the same premises. It is equipped with well furnished, spacious classrooms, online and offline libraries and full-fledged laboratories with adequate equipment for each concerned department as per Indian Nursing Council guidelines.`,
  },
  {
    img: labs,
    title: "Clinical Facilities",
    desc: `The Institution is Affiliated to Government Civil Hospital Faridabad (BK Hospital) which provides them ample opportunities to perfect their skills in rendering comprehensive patient care. Students are exposed to all newer technologies used in the medical field as they are getting supervised training as per guidelines from reputed private hospitals within near vicinity.`,
    link: "/computerlabs",
  },
  {
    img: safety,
    title: "Community Facilities",
    desc: `Institution is affiliated to community health centers in Urban and Rural areas enabling the students to enhance their competencies in functioning as primary health care providers. As future community health nurse to serve the community as an accessible resource whenever the community requires their help.
 
    The Community Health Nursing Department covers Both Rural & Urban Health care services.
    
     `,
    link: "/safety",
  },
  // {
  //   img: internet,
  //   title: "Student Welfare Activities",
  //   desc: `Along with prescribed curriculum, students are also excelling in innate abilities by
  //       taking part in State and National level competitions organized...`,
  //   link: "/internet",
  // },
  // {
  //   img: Add,
  //   title: "Extra Curricular Activities",
  //   desc: `Sports day is celebrated every year to encourage health and activities. Indoor and
  //       outdoor games are the part of daily routine for hostellers.`,
  //   link: "/otherfacilites",
  // },
];

export default infraCardData;

export const classroomData = [
  {
    title: "Classrooms:",
    main_desc:
      "School is situated at Anangpur village in a pollution free ecofriendly environment with nature at about in 4acre land area along with hostel in the same premises.",
    desc: "Well furnished, ventilated and spacious rooms provides ambience to facilitate teaching-learning process. Classrooms are provided with facilities for utilizing modern educational technologies. ",
    desc2:
      "Our classrooms are spacious, well furnished, air-conditioned and equipped with comfortable chairs and tables for our students to receive all the comfort they need to indulge in their studies and activities. Every classroom has a projector and whiteboard for training sessions to be conducted smoothly and with the best of quality",
  },
  {
    title: "Library:",

    desc: "Library has a vast collection of latest edition of books as well as National and International Journals. It is also provided with digital facility for students and teachers to access e-books for continuous upgradation of knowledge.",
  },
  {
    title: "Labs:",

    desc: "Students get opportunity to learn hands on skill development at full-fledged laboratories with adequate supplies and equipments for each concerned department.",
  },
];

export const computerData = [
  {
    title: "Computer Labs",
    main_desc: "",
    desc: "Institution is affiliated to community health centers in Urban and Rural areas enabling the students to enhance their competencies in functioning as primary health care providers.Community Health Nursing Department covers Both Rural &amp; Urban Health care services.",
    desc2:
      "All our VTCs providing IT Courses are equipped with sufficient number of computer systems, which ensures that every student gets individual practice time for the duration of their practice session. The labs fulfil the practical learning needs of students from all programmes with software requirements of that particular course.",
    desc3:
      "The computer labs have been designed in a unique layout and the PCs are upgraded to meet the demands of changing technologies. The laboratories are used by students pursuing Web Design, Tally, Office Operations and Retail courses. All systems are LAN connected and Wi-Fi is available throughout the VTC Centres. Each computer has a set of software which includes advanced Microsoft, Adobe, Corel Draw and other learning packages.",
  },
  {
    title: "Workshops",
    main_desc: "",
    desc: `Workshops are the most fun spaces for the students. They act as a
        playground for students to engage in hands-on learning and exploration of
        their curiosities with fun and creativity.`,
    desc2: `A workshop allows a student to learn more about anything with a more
        hands-on approach that is mostly lacking in classroom teaching. It creates
        the perfect circumstances for doubts to come up during practical
        application and therefore to be addressed during the learning process.`,
    desc3: `Our VTCs have a large range of workshops ranging from Electrical,
        Automobile and Welding Workshops at VTC Raipur, Handloom Weaving
        Workshop at VTC Bhakatpara and Cutting &amp; Tailoring Workshop at VTC
        Anangpur for the concerned courses at these centres.`,
  },
];

export const safetyData = [
  {
    title: "Safety & Security:",
    desc: "The safety and wellbeing of our staff and students is of utmost priority to us and we take care of it in all ways.",
  },
];

export const safetymap = [
  {
    title: "CCTV Systems:",
    desc: "Our vocational training centres are installed with CCTV systems at strategic locations within the premises. Our security staff and other concerned authorities keep a close tab on the happenings in and around the premises to prevent any breach of security.",
  },
  {
    title: "Safe Drinking Water, Hygiene and Sanitation:",
    desc: "Every Vocational Training Centre is provided with quality water filters, ensuring that students have easy access to clean and safe drinking water. Our efficient and hard-working administrative staff ensures that proper hygiene and sanitation is maintained throughout the premises",
  },
  {
    title: "First Aid Kit & Other Safety Provisions:",
    desc: "Every centre has a first aid kit for handling minor medical conditions.",
    desc2:
      "In unforeseen cases like accidents and emergencies, our staff has been provided with database for seeking quick emergency support from concerned authorities and take prudent action keeping in mind the safety and wellbeing of all involved.",
    desc3:
      "Fire extinguishers are installed at all vocational training centres for fire safety and mock drills are conducted from time to time.",
  },
];

export const internetData = [
  {
    title: "Internet Facility:",
    desc: "We offer our students access to a world of opportunities and knowledge with high speed internet facility in an endeavour to bring the world closer to them. All our computer systems are LAN connected and Wi-Fi is enabled throughout the centres.",
    desc2:
      "Teaching and learning at all our Vocational Training Institutes is supported by free internet access, thereby improving learning outcomes considerably. Broadband-enabled learning tools facilitate more interactive, personalized learning and we feel every child deserves to reap its benefits.",
    desc3:
      "Use of audio visual tools with the help of internet, enriches the learning process, getting students excited about a topic and reinforcing learned concepts.Students are also able to easily attend webinars and online workshops that are organised for them for personality development, technical skill enhancement, placement support or any additional training support.",
  },
];

export const extraCurricularData = [
  {
    title: "Extracurricular Activites",
    desc: `Other than academic activities students are engaged in various extracurricular activities like poster making, dance competition, singing competition, model making competition etc. Sports day is celebrated every year to encourage health and activities. Indoor and outdoor games are part of the daily routine for hostellers.`,
    desc2:
      "Teaching and learning at all our Vocational Training Institutes is supported by free internet access, thereby improving learning outcomes considerably. Broadband-enabled learning tools facilitate more interactive, personalized learning and we feel every child deserves to reap its benefits.",
    desc3:
      "Use of audio visual tools with the help of internet, enriches the learning process, getting students excited about a topic and reinforcing learned concepts.Students are also able to easily attend webinars and online workshops that are organised for them for personality development, technical skill enhancement, placement support or any additional training support.",
  },
];

export const otherData = [
  {
    title: "Conference Room",
    desc: "We have aesthetically appealing, spacious conference rooms that echo ideas during knowledge sharing and brainstorming sessions, student workshops, meetings and other important discussions.",
    desc1:
      "The conference rooms are equipped with white boards, projectors and screens, audio visual systems and other infrastructural facilities that are vital in facilitating smooth execution of various activities.",
  },
  {
    title: "Placement cell",
    desc: "We take placements of our students very seriously. Our courses are aimed at not just skilling them but also enabling them for livelihood opportunities and gainful employment. In order to take constructive action towards this goal, we have a placement cell that aligns relevant employment opportunities for our students by typing up with industry partners and potential employers. They also take efforts to prepare students through guidance sessions, guest lectures, mock interviews, resume-building sessions, etc.",
  },
  {
    title: "Library",
    desc: "Any centre of education and skilling would be incomplete without a library; a space that allows students to indulge in the old-school joy of learning from books, magazines and other printed treasure-houses of knowledge.",
  },
  {
    title: "Wash-rooms",
    desc: "Students have access to well sanitized and hygienic washrooms. Our staff ensures strict compliance to top-notch hygiene practices to ensure well-being of everyone. Thorough cleaning and sanitization is ensured routinely with availability of all essential resources.",
  },
];

export const classroomImg = [
  {
    img: first,
  },
  {
    img: second,
  },
  {
    img: third,
  },
  {
    img: fourth,
  },
];

export const labImg = [
  {
    img: firstlab,
  },
  {
    img: secondlab,
  },
  {
    img: thirdlab,
  },
  {
    img: fourthlab,
  },
  {
    img: fifthlab,
  },
  {
    img: sixlab,
  },
  // {
  //   img: sevenlab,
  // },
];
export const internetImg = [
  {
    img: firstint,
  },
  {
    img: secondint,
  },
  {
    img: thirdint,
  },
  {
    img: fourthint,
  },
  {
    img: fifthint,
  },
];
export const otherImg = [
  {
    img: firstother,
  },
  {
    img: secondother,
  },
  // {
  //   img: thirdother,
  // },
  {
    img: fourthother,
  },
];
export const safetyImg = [
  {
    img: firstsafety,
  },
  // {
  //   img: secondsafety,
  // },
  {
    img: thirdsafety,
  },
  {
    img: fourthsafety,
  },
  {
    img: fifthsafety,
  },
];
