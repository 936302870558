import React, { useEffect, useState } from "react";
// import { Table, Input, DatePicker, Typography, Space, Button } from "antd";
import { Table, Input, DatePicker, Typography, Space, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { getStudentTCData } from "../../service/api";
import moment from "moment";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";

const keys = [
  "REG_CODE",
  "STD_NAME",
  "STD_FATHER_NAME",
  "STD_MOTHER_NAME",
  "STD_LAST_CLASS",
  "STD_TCNO",
  "STD_TC_ISSUE_DATE",
];

const StudentTCTable = () => {
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const { RangePicker } = DatePicker;

  const fetchStudentTCData = async () => {
    setIsLoading(true);
    const res = await getStudentTCData();
    setRows(res);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchStudentTCData();
  }, []);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isShow, setIsShow] = useState(false);

  const handleClick = () => {
    setIsShow(!isShow);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <RangePicker
          style={{ marginBottom: 8, display: "block" }}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e ? [e] : []);
          }}
          onPressEnter={() => {
            confirm();
            setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex);
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? moment(record[dataIndex]).isBetween(value[0], value[1], "day", "[]")
        : "",
    render: (text) => text,
  });

  const columns = [
    {
      title: "Code",
      dataIndex: "REG_CODE",
      key: "REG_CODE",
    },
    {
      title: "Name",
      dataIndex: "STD_NAME",
      key: "STD_NAME",
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.STD_NAME.toLowerCase().localeCompare(b.STD_NAME.toLowerCase()),
    },
    {
      title: "Father Name",
      dataIndex: "STD_FATHER_NAME",
      key: "STD_FATHER_NAME",
    },
    {
      title: "Mother Name",
      dataIndex: "STD_MOTHER_NAME",
      key: "STD_MOTHER_NAME",
    },

    {
      title: "Last Class",
      dataIndex: "STD_LAST_CLASS",
      key: "STD_LAST_CLASS",
    },
    {
      title: "TC Number",
      dataIndex: "STD_TCNO",
      key: "STD_TCNO",
    },
    {
      title: "TC Issue Date",
      key: "STD_TC_ISSUE_DATE",
      dataIndex: "STD_TC_ISSUE_DATE",
      ...getColumnSearchProps("STD_TC_ISSUE_DATE"),
    },
    {
      title: "TC URL",
      key: "STD_TCURL",
      dataIndex: "STD_TCURL",
      render: (_, record) => (
        <Typography.Link href={record.STD_TCURL} target="_blank">
          Link
        </Typography.Link>
      ),
    },
  ];

  const search = (rows) => {
    return rows.filter((item) => {
      return keys.some((key) => {
        return item[key]
          ?.toString()
          .toLowerCase()
          .includes(searchValue.toString().toLowerCase());
      });
    });
  };

  return (
    <div className="home_main">
      <Navbar isScrolled={true} handleClick={handleClick} />
      {/* <NavbarMenu isShow={isShow} handleClick={handleClick} /> */}
      <div
        style={{
          marginTop: "100px",
          marginLeft: "25px",
          fontSize: "20px",
          fontWeight: "700",
        }}
      >
        STUDENT TC DETAILS
      </div>
      <div className="m-5">
        <Input
          className="m-1"
          placeholder="Search"
          onChange={(e) => setSearchValue(e.target.value)}
        />

        <Table
          columns={columns}
          dataSource={search(rows)}
          loading={isLoading}
          rowKey="REG_CODE"
        />
      </div>
      <Footer />
    </div>
  );
};

export default StudentTCTable;
