import React from "react";
// import Home from "../../Home/Home";
import Navbar from "../../navbar/Navbar";
import Sidebar from "../../Sidebar/Sidebar";
import Footer from "../../footer/Footer";
import LandingImage from "../../LandingImage";
import physicalCover from "../../../images/covers/physicalCover.jpeg";

import PagesCard from "../PagesCard";

const Classroom = ({ data, imgData }) => {
  return (
    <>
      <Navbar />
      <LandingImage cover={physicalCover} />

      <div className="contentmaincont">
        <Sidebar />

        <div className="aboutus">
          <div className="firsttext">
            {data.map((item, index) => (
              <>
                <div className="mandatory_heading1">{item.title}</div>
                <p style={{ color: "rgba(0, 0, 0, 0.61)" }}>{item.desc}</p>
              </>
            ))}

            {/* <p
              style={{
                fontWeight: "700",
                fontSize: "20px",
                textAlign: "justify",
              }}
            >
              {data[0].main_desc}
            </p> */}

            {/* <p style={{ color: "rgba(0, 0, 0, 0.61)" }}>{data[0].desc2}</p>
            <p style={{ color: "rgba(0, 0, 0, 0.61)" }}>{data[0].desc3}</p> */}
          </div>

          <PagesCard data={imgData} />
        </div>
      </div>

      <div className="mt-5" style={{ background: "#F8F9F9" }}>
        <Footer />
      </div>
    </>
  );
};

export default Classroom;
