import React from "react";

import "./infra.css";
import Infracard from "./Infracard";
import infraCardData from "./infracarddata";

const Infra = () => {
  return (
    <>
      <section className="infra" style={{ background: "#F8F9F9" }}>
        <div className="container">
          <h2 className="text-center infrahead">
            Infrastructure and Facilities
          </h2>

          <center>
            <div className="row mt-5">
              <Infracard data={infraCardData} />
            </div>
          </center>
        </div>
      </section>
    </>
  );
};

export default Infra;
