import React from "react";
import Footer from "../footer/Footer";
import Home from "../Home/Home";
import LandingImage from "../LandingImage";
import Navbar from "../navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import Alumni from "./Alumni";
import AlumniCover from "../../images/covers/AlumniCover.jpeg";

const Inspired = ({ alumniData }) => {
  return (
    <>
      <Navbar />
      <LandingImage cover={AlumniCover} />

      <div className="contentmain-cont">
        <div className="width">{/* <Sidebar /> */}</div>
        <Alumni data={alumniData} />
      </div>

      <div style={{ background: "#F4F6F6" }}>
        <Footer />
      </div>
    </>
  );
};

export default Inspired;
