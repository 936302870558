import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import jinsi from "../../images/message/Jinsi.png";
import principal from "../../images/message/principle.jpeg";
import "./leadership.css";
import sumanta from "../../images/message/Sumanta.jpg";

import debaroti from "../../images/message/debaroti.png";
const Main = () => {
  return (
    <>
      <div className="contentmaincont">
        <div style={{ width: "min-content" }}>
          <Sidebar />
        </div>

        <div className="aboutus">
          <div className="firsttext">
            <div className="mandatory_heading1 messageHeading">
              Message From Leadership
            </div>
          </div>

          <div>
            <div
              className="president"
              style={{
                marginBottom: "1rem",
                marginTop: "0px",
              }}
            >
              <div
                className="alumni"
                style={{ display: "flex", marginTop: "1rem!important" }}
              >
                <div className="alumni_img">
                  <img
                    src={jinsi}
                    alt="president img"
                    className="content_img"
                  />
                </div>

                <div>
                  <div className="alumni_head">President</div>
                  <div className="alumni_name">Rakesh Jinsi</div>
                  <div
                    className="alumni_name"
                    style={{ marginBottom: "0.5rem" }}
                  >
                    SOS Children's Village Of India
                  </div>
                  <div className="alumni_text">
                    Rakesh Jinsi, an engineer by degree, has 41 years of
                    experience in leadership roles at several institutions,
                    including SOS Children's Villages. Presently running
                    Khushboo, a daycare centre for children with severe mental
                    and multiple disabilities, he has always been active in
                    national level bodies such as the National Human Rights
                    Commission and Voluntary Action Network of India (VANI), the
                    Apex body of the NGO sector.
                  </div>
                </div>

                <div></div>
              </div>

              <div className="alumni_text_container">
                <div
                  className="alumni_text"
                  style={{ marginTop: "0.5rem !important" }}
                >
                  "I firmly believe that each and every child deserves an
                  education that is advanced, detail-oriented yet nurturing and
                  holistic. As President of SOS Children’s Villages of India, I
                  wish to provide the best possible learning experience to the
                  future leaders of our country, so that all children are able
                  to reach their maximum potential and achieve their dreams.
                  Students end up spending most of their childhood within
                  classrooms, so it is essential that within those walls they
                  are imparted proper theoretical knowledge, practical aptitude,
                  communal values, essential life skills, and an understanding
                  of how the world works and their place in it. , "Adequate
                  grooming and learning of children at a young age leads to them
                  being strong, confident, hard-working and intelligent
                  individuals, attributes that I deem necessary for the new era
                  of technology and scientific progress we are heading towards."
                </div>
              </div>
            </div>

            <div
              className="president"
              style={{
                marginBottom: "1rem",
                marginTop: "0px",
              }}
            >
              <div
                className="alumni flex-column-reverse flex-md-row"
                style={{ display: "flex", marginTop: "1rem!important" }}
              >
                <div>
                  <div className="alumni_head">Secretary General</div>
                  <div className="alumni_name">Sumanta Kar</div>
                  <div
                    className="alumni_name"
                    style={{ marginBottom: "0.5rem" }}
                  >
                    SOS Children's Village Of India
                  </div>
                  <div className="alumni_text">
                    Education at SOS Hermannn Gmeiner Schools has always meant
                    the Holistic Development of a student – we believe that each
                    child is entitled to the best possible skill set for their
                    future, therefore, each child entrusted with us will receive
                    an education that is in its finest form.
                  </div>
                </div>
                <div className="alumni_img">
                  <img
                    src={sumanta}
                    alt="president img"
                    className="content_img"
                  />
                </div>
              </div>

              <div className="alumni_text_container">
                <div
                  className="alumni_text"
                  style={{ marginTop: "1rem !important" }}
                >
                  Education is preparation for life, it should be an enjoyable,
                  interactive and ongoing process to make the students confident
                  young adults, sensitive to global issues, committed citizens
                  who are aware of the happenings of the world and sensible
                  individuals with a positive attitude.
                </div>
                <div className="alumni_text" style={{ marginTop: "1rem" }}>
                  Students should have a strong character and a good
                  understanding of their humane and emotional sides. All our
                  Hermannn Gmeiner Schools have been actively engaged in
                  relating to, understanding and working with communities around
                  our schools.
                </div>
                <div className="alumni_text" style={{ marginTop: "1rem" }}>
                  Safeguarding of children remains a top priority and they are
                  nurtured through a theme-based curriculum and actively engage
                  in experiential learning. Our teachers and principals play a
                  pivotal role in the development of each child. Our alumni are
                  our brand ambassadors, who leave a lasting footprint in
                  whatever profession they choose to pursue. Each student who
                  joins us becomes a future world leader, ready to bring
                  progressive change and make our nation proud.
                </div>
              </div>
            </div>

            <div
              className="president"
              style={{
                marginBottom: "1rem",
                marginTop: "0px",
              }}
            >
              <div
                className="alumni"
                style={{ display: "flex", marginTop: "1rem!important" }}
              >
                <div className="alumni_img">
                  <img
                    src={debaroti}
                    alt="president img"
                    className="content_img"
                  />
                </div>

                <div>
                  <div className="alumni_head">
                    Head- Education & Child Development
                  </div>
                  <div className="alumni_name">Debaroti Bose</div>
                  <div
                    className="alumni_name"
                    style={{ marginBottom: "0.5rem" }}
                  >
                    SOS Children's Village Of India
                  </div>
                  <div className="alumni_text">
                    I believe that school should be the experience of a
                    lifetime. Our school experience sets the stage for success
                    later in life and provides a lifetime of positive memories
                    for our students. We are proud of our continuous goal of
                    providing an excellent education for our students.We have a
                    dynamic, hardworking faculty who are involved in education
                    to benefit the students.The entire gamut of school education
                    is transforming the teaching-learning process.
                  </div>
                </div>

                <div></div>
              </div>

              <div className="alumni_text_container">
                <div
                  className="alumni_text"
                  style={{ marginTop: "1rem !important" }}
                >
                  In the modern concept of schooling, teaching has become
                  redundant, paving the way for the facilitation of learning
                  through knowledge sharing. The wave of digital learning and
                  cloud computing is reaching new heights.
                </div>
                <div className="alumni_text" style={{ marginTop: "1rem" }}>
                  In all this whirlwind of new-age technology, it is important
                  to charge ahead but with one’s head firmly on one’s shoulders.
                  Hermann Gmeiner School strives to inculcate moral values,
                  manners, responsibilities among students so that they may
                  build a world free of all the evils.
                </div>
                <div className="alumni_text" style={{ marginTop: "1rem" }}>
                  Students here are encouraged to dream, groomed to give wings
                  to their dreams, to take off and explore the unexplored. The
                  plethora of interesting & exciting opportunities available to
                  youngsters today often leave them with an array of things to
                  do.
                </div>
                <div className="alumni_text" style={{ marginTop: "1rem" }}>
                  It is important therefore to help them build their focus on
                  the subject that draws them most and gives shape to their
                  vision. A student is made to blossom intellectually, morally,
                  and spiritually. As Swami Vivekananda said, “Take up one idea.
                  Make that one idea your life – think of it, dream of it, and
                  live on that idea. Let the brain, muscles, nerves, every part
                  of your body, be full of that idea, and just leave every other
                  idea alone. This is the way to success.”
                </div>
              </div>
            </div>

            <div
              className="president"
              style={{
                marginBottom: "1rem",
                marginTop: "0px",
              }}
            >
              <div
                className="alumni flex-column-reverse flex-md-row"
                style={{ display: "flex", marginTop: "1rem!important" }}
              >
                <div>
                  <div className="alumni_head">Principal</div>
                  <div className="alumni_name">Inderpreet Kaur</div>
                  <div
                    className="alumni_name"
                    style={{ marginBottom: "0.5rem" }}
                  >
                    SOS School of Nursing
                  </div>
                  <div className="alumni_text">
                    Dear Friends, At the very outset I would like to thank
                    Almighty who is the creature of this beautiful world as well
                    as my s and my teachers who encouraged me to become a nurse
                    and serve the humanity. I am proud to be a nurse “Being a
                    Nurse means to hold all your own tears and start drawing
                    smiles on people’s faces” Dana Basem The above said line
                    tells us all about nursing. Seeing critical patients and the
                    agony of their relatives we forget everything and extend our
                    hands to patients those who are suffering due to critical
                    illness or severe pain even though we may also be suffering
                    from pain. Nurses take oath at the beginning of their career
                    that knowingly they will not do any harm to any patient.
                    Though there are medical errors, happening in hospitals and
                    if you consider statistics, this is enormous in number (1 in
                    300 patients may be a victim of medical harm).
                    <br />
                    Then what is the solution? Nursing can be opted as a career
                    if an individual is really interested to serve the humanity
                    and take this profession as a challenge to uphold standards
                    of this noble profession. For a common man, the picture of a
                    nurse is just to administer medicine and injection with some
                    hygienic care. Whereas Nursing is beyond this; Nurse is the
                    person who witness first and last breath of life, who takes
                    decision whether a dose correctly diluted for
                    administration, who understands the critical alarms of
                    monitor, who saves life by providing CPR, who stands for a
                    long surgery along with surgeon, who knows all preventive
                    aspects of infection control practices. There are many more
                    examples which can be quoted. In fact, it was during
                    COVID-19 outbreak, world came to know the value of a nurse.
                    At our School we are committed to quality education. We are
                    preparing them for holistic development so that health care
                    sector recognizes our contribution towards patient safety by
                    selecting (placement) our student nurses on merit. The motto
                    of our Institute is “ be safe , patient first”.
                  </div>
                </div>
                <div className="alumni_img">
                  <img
                    src={principal}
                    alt="president img"
                    className="content_img2"
                  />
                </div>
              </div>

              <div className="alumni_text_container">
                <div
                  className="alumni_text"
                  style={{ marginTop: "1rem !important" }}
                >
                  He must have a strong desire and an equally strong commitment
                  to work towards achieving his objectives, should be very
                  optimistic, with a sharp focus on potential and not problems,
                  and should always visualize the best possible outcome and
                  benefits he wants to achieve and let this motivate and
                  energize him to work towards it.
                </div>
                <div className="alumni_text" style={{ marginTop: "1rem" }}>
                  Hermannn Gmeiner School has set up its tradition of keeping
                  children motivated to channelize their energy towards
                  something export constructive and enriching through
                  Experiential Learning with a strong focus on achieving
                  learning objectives. Students are given projects/ assignments
                  that require them to learn the necessary material themselves.
                </div>
                <div className="alumni_text" style={{ marginTop: "1rem" }}>
                  The key to this is the ability to get the information they
                  need anytime anywhere without being in the physical presence
                  of a facilitator.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
