import React from "react";

import Home from "../Home/Home";
import LandingImage from "../LandingImage";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import Academics from "./Academics";
import acad from "../../images/covers/acadamicsCover.jpeg";

const AcademicHome = () => {
  return (
    <>
      <Navbar />
      <LandingImage cover={acad} />
      <Academics />
      <div style={{ background: "#F8F9F9" }}>
        <Footer />
      </div>
    </>
  );
};

export default AcademicHome;
