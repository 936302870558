import React from "react";
import img from "../../images/photo-gallery/1.jpeg";
import About1 from "../../images/home/About1.jpeg";

import OurVision from "../../images/photo-gallery/OurVision.jpeg";
import mission from "../../images/home/Mission.jpeg";
import vision from "../../images/home/vision.jpeg";
import "./about.css";
const AboutInstitution = () => {
  return (
    <>
      <div className="institue">
        <div>
          <div
            className="institue_heading "
            style={{
              marginTop: "0rem",
              marginBottom: "3rem",
            }}
          >
            <h1 className="text-center institue_heading">
              {" "}
              Welcome to SOS Nursing School, Faridabad
            </h1>
          </div>

          <div style={{ marginBottom: "50px" }}>
            <div
              className="firstinstitute lg:flex-row flex-col"
              style={{
                display: "flex",
                marginBottom: "20px",
                marginTop: "4rem",
              }}
            >
              <img src={About1} alt="" className="aboutimg shadow-2xl" />

              <div className="ms-0 ms-md-4">
                <div className="about_sos">About SOS Nursing School</div>

                <p className="sos_para">
                  <b>SOS Nursing School, Faridabad</b> is one of the pioneer
                  institution in the field of nursing. Preparing nurses for last
                  two and half decades. In 1998 when nursing was not well known
                  as a profession and there were very few takers; SOS Nursing
                  school was the only name at Faridabad and adjacent areas of
                  Delhi for one and only Centre for nursing education. Keeping
                  continuous quality improvement in process nursing school
                  upgraded its courses in 2004 from ANM to GNM.{" "}
                </p>

                <p className="sos_para">
                  As Corona became pandemic in entire world nurses became angels
                  who worked day and night for patients and simultaneously
                  profession got its due respect. Keeping all these in mind SOS
                  nursing school is also striving to raise to the next level
                  which is Collegiate program within near future. Our School is
                  affiliated by <b>Indian Nursing Council</b> as well as{" "}
                  <b>
                    Haryana Nurses council and DMER – Directorate of Medical
                    Education and Research, Haryana
                  </b>
                  .
                </p>
              </div>
            </div>
            <div
              className="firstinstitute lg:flex-row flex-col "
              style={{ display: "flex" }}
            >
              <img src={mission} alt="" className="aboutimg shadow-2xl" />
              <div className="d-flex flex-column">
                <div className="ms-0 ms-md-4 mt-4">
                  <div className="about_sos" style={{ marginTop: "0rem" }}>
                    Our Mission
                  </div>

                  <p className="sos_para">
                    Prepare world class nurses as per international standards to
                    ensure patient safety and quality of nursing.
                  </p>
                </div>
                <div className="d-flex flex-column ">
                  <div className="ms-0 ms-md-4" >
                    <div className="about_sos" style={{ marginTop: "0rem" }}>
                      Our Vision
                    </div>

                    <p className="sos_para">
                      “Excellence in nursing profession with human and holistic
                      approach”{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div
              className="firstinstitute lg:flex-row flex-col mt-4"
              style={{ display: "flex" }}
            >
              <img src={vision} alt="" className="aboutimg shadow-2xl" />

              <div className="d-flex flex-column align-items-center">
                <div style={{ marginLeft: "1.5rem" }}>
                  <h1 className="about_sos">Our Vision</h1>

                  <p className="sos_para">
                    “Excellence in nursing profession with human and holistic
                    approach”{" "}
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutInstitution;
