
import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";


import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";



import { Pagination, Navigation, Autoplay } from "swiper";

const ParamedicalHomesliding = () => {
    return (
        <>

            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                loop={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
            >

                <SwiperSlide>
                    <img src="./images/raipurpageimg/1.jpg" alt="" />
                    <center>
                        <div className="bottomleft box">
                            <h5 className="h">Hermann Gmeiner Vocational Training Institute, Raipur</h5>
                        </div>
                    </center>

                </SwiperSlide>
                <SwiperSlide>
                    <img src="./images/Nizampageimg/2.jpg" alt="" />
                    <center>
                        <div className="bottomleft box">
                            <h5 className="h">Hermann Gmeiner Vocational Training Institute, Raipur</h5>
                        </div>
                    </center>

                </SwiperSlide>
                <SwiperSlide>
                    <img src="./images/Nizampageimg/3.jpg" alt="" />
                    <center>
                        <div className="bottomleft box">
                            <h5 className="h">Hermann Gmeiner Vocational Training Institute, Raipur</h5>
                        </div>
                    </center>

                </SwiperSlide>



            </Swiper>

        </>
    )
}

export default ParamedicalHomesliding
