import React from "react";
import "./nurse.css";

const Nurseplacement = ({ imgData }) => {
  return (
    <>
      <section className="placement">
        {/* <div className="container-fluid">
          <h2 className="text-center mb-2">placement partners</h2>

          <div className="sliding">

                        <div className="slide-track">


                            {imgData?(<>
                              

                              {imgData.map((e,ind)=>{

                                return <div className='slide' key={ind}>

                                  <img src={e.img} alt="img" />

                                </div>
                              })}


                              
                            
                            
                            
                            
                            </>):(<></>)}
                         
                        </div>
                    </div>
        </div> */}
      </section>
    </>
  );
};

export default Nurseplacement;
