import React from "react";
import "../Aboutinstitue/about.css";

const NurseCoursedata = (props) => {
  console.log(props);
  return (
    <>
      <section className="courseoffer">
        <div
          className="container"
          style={{ color: "rgba(0, 0, 0, 0.61)", fontWeight: "500" }}
        >
          <h1> Nursing Courses</h1>
          <p className="sos_para1 pt-4">{props.data.text}</p>
          <p className="sos_para1 ">{props.data.subtext}</p>
          <p className="sos_para1  ">{props.data.subtext2}</p>
          <p className="sos_para1  py-2">{props.data.subtext3}</p>
        </div>
      </section>
    </>
  );
};

export default NurseCoursedata;
