import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import "./academics.css";

import Nursing from "./Nursing";
import { Row, Col } from "react-bootstrap";
import Student from "./Student";
import Staff from "./Staff";
import Nurse from "./Nurse";
import Placement from "./Placement";
const Academics = () => {
  return (
    <>
      <div className="contentmaincont">
        <Sidebar />

        <div className="aboutus">
          <div className="firsttext">
            <div className="mandatory-heading1">
              <div>Academic Activities</div>
            </div>
          </div>

          <div>
            <div className="acad_para">
              <div>
                <div className="acad_para1">
                  Assessments are used to measure student learning outcomes on
                  an ongoing basis. These assessments reveal how and what
                  students are learning during the course and often inform next
                  steps in teaching and learning.
                </div>
              </div>
            </div>
          </div>

          <div style={{ marginBottom: "1.5rem" }}>
            {/* <h3
              style={{
                marginTop: "2rem",
                textAlign: "center",
                marginLeft: "-2rem",
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                color: "#009EE0",
                fontSize: "42px",
                lineHeight: "51px",
              }}
            >
              Highlights
            </h3> */}
            <div className="highlightss">
              <Row>
                <Col style={{ display: "flex", justifyContent: "center" }}>
                  <Nursing />
                </Col>
                <Col style={{ display: "flex", justifyContent: "center" }}>
                  <Student />
                </Col>
              </Row>
              <Row>
                <Col style={{ display: "flex", justifyContent: "center" }}>
                  <Staff />
                </Col>
                <Col style={{ display: "flex", justifyContent: "center" }}>
                  <Nurse />
                </Col>
              </Row>
              <Row>
                <Col style={{ display: "flex", justifyContent: "center" }}>
                  <Placement />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Academics;
