import React from "react";
import Footer from "../footer/Footer";

import Navbar from "../navbar/Navbar";
import Alumni from "../Nurse Training/Alumni";
import Nizamudincard from "../Nurse Training/Nursecard";

import Nurseplacement from "../Nurse Training/Nurseplacement";
import raipurcardData from "./RaipurcardData";
import ParamedicalHomesliding from "./ParamedicalHomesliding";
import ParamedicalCourse from "./ParamedicalCourse";

import imgData from "./ImgData";
import ParamedicalCard from "./ParamedicalCard";
import LandingImage from "../LandingImage";
import paramedical from "../../images/covers/paramedical.jpeg";
const ParamedicalHome = () => {
  return (
    <>
      <Navbar />
      <LandingImage cover={paramedical} />
      {/* <ParamedicalHomesliding/> */}
      <ParamedicalCourse />
      <ParamedicalCard />
      <Nurseplacement imgData={imgData} />
      {/* <Alumni link="/raipuralumni" /> */}
      {/* <Nizamudincontact/> */}
      <div style={{ background: "#f4f6f6" }}>
        {" "}
        <Footer />
      </div>
    </>
  );
};

export default ParamedicalHome;
