import React from 'react'
import Footer from '../footer/Footer'
import Home from '../Home/Home'
import Navbar from '../navbar/Navbar'
import Donor from './Donor'
import Homeslide from './Homeslide'

const Donorhome = () => {
  return (
    <>
    <Navbar/>
    <Homeslide/>
    <Donor/>
    <Footer/>
      
    </>
  )
}

export default Donorhome
