import dataentry from "../../images/Nizamimages/dataentry.jpg";
import GNM from "../../images/Nizamimages/GNM.jpeg";
import ANM from "../../images/Nizamimages/ANM.jpg";
import labAssistant from "../../images/Nizamimages/labAssistant.jpg";
import Eye from "../../images/Nizamimages/Eye.jpg";
import ortho from "../../images/Nizamimages/ortho.png";
import diet from "../../images/Nizamimages/diet.jpg";
import sanitary from "../../images/Nizamimages/sanitary.jpg";
import ecg from "../../images/Nizamimages/ecg.jpeg";

import feeStructureGNM from "../../pdf/Fee Structure GNM course 2024-2025.pdf"
import feeStructureANM from "../../pdf/Fee Structure ANM course 2024-2025.pdf"

export const GneralNursingCourse = [
  {
    id: 1,
    img: GNM,
    title: "General Nursing and Midwifery",
    desc: `Diploma in General Nursing and Midwifery (GNM) is a three-year programme aimed to prepare students to work effectively as members of the health team. This job-oriented programme comprises subjects like Nursing Fundamentals Anatomy & Physiology Psychology Biology Sociology and First Aid.
    `,
    subdesc: "",
    eligibility: "10+2 pass (any stream) with 40% marks",
    duration: "3 years",
    feeStructure: feeStructureGNM
  },
];
export const AuxiliaryCourse = [
  {
    id: 1,
    img: ANM,
    title: "Auxiliary Nurse Midwifery",
    desc: `ANM or Auxiliary Nursing Midwifery is a two-year diploma programme in the field of Nursing. Interested candidates can apply for an ANM diploma after passing their class 12 exam in Science or Arts stream.
    `,
    subdesc: "",
    eligibility: "10+2 pass (any stream) with 40% marks",
    duration: "2 years",
    feeStructure: feeStructureANM
  },
];
export const LabCourse = [
  {
    id: 1,
    img: sanitary,
    title: "BSS Diploma in Sanitary Health Inspector",
    desc: `Sanitary health inspectors are professionals who are focused on prevention, consultation, investigation, and education of the community regarding health risks and maintaining a safe environment. Public health is an important matter of concern for the municipal bodies in various states.`,
    subdesc: "",
    eligibility: "10 th pass (any stream)",
    duration: "1 years",
  },
];
export const eyeCourse = [
  {
    id: 1,
    img: diet,
    title: "Certificate in Dietician",
    desc: `Dietitian Courses deal with an individual's diet and health. Courses in Dietitian are designed in a way that it prepares the individual to acquire knowledge related to diet, human body, about different types of food and their effect on the body.`,
    subdesc: "",
    eligibility: "10 th pass (any stream)",
    duration: "1 years",
  },
];
export const orthoCourse = [
  {
    id: 1,
    img: ecg,
    title: "ECG Technician ",
    desc: `
    An ECG technician, also called a cardiographic technician, uses electrocardiogram machines to run diagnostic tests to measure a patient's heart rate, monitor cardiac rhythm.`,
    subdesc: "",
    eligibility: "10 th pass (any stream)",
    duration: "six months",
  },
];
export const dietCourse = [
  {
    id: 1,
    img: labAssistant,
    title: "Lab Assistant",
    desc: `Lab assistants are responsible for helping technologists and scientists during lab tests and research. Their duties include processing samples, classifying results, and recording findings.
    `,
    subdesc: "",
    eligibility: "10 th pass (any stream)",
    duration: "1 years",
  },
];
export const diplomaCourse = [
  {
    id: 1,
    img: Eye,
    title: "Eye Technician",
    desc: `Eye Technician is an allied healthcare professional who possess comprehensive knowledge and experience in vision management, and to assist ophthalmologist and optometrist in prevention of blindness`,
    subdesc: "",
    eligibility: "10 th pass (any stream)",
    duration: "1 years",
  },
];
export const ecgCourse = [
  {
    id: 1,
    img: ortho,
    title: `Ortho & Trauma Care Technician`,
    desc: `Trauma Care Technician provides immediate care to the injured and efficient care in accidental cases. These technicians are imparted knowledge of disease processes, bedside procedures and technical skills to handle critical/ emergency/ traumatic cases.`,
    subdesc: "",
    eligibility: "10 th pass (any stream)",
    duration: "1 years",
  },
];
