import React from "react";
import Footer from "../footer/Footer";
import Home from "../Home/Home";
import LandingImage from "../LandingImage";
import Navbar from "../navbar/Navbar";
import Main from "./Main";
import message from "../../images/covers/messageCover.jpeg";

const Leadership = () => {
  return (
    <>
      <Navbar />
      {/* <Home/> */}
      <LandingImage cover={message} />
      <Main />
      <div style={{ background: "#F8F9F9" }}>
        <Footer />
      </div>
    </>
  );
};

export default Leadership;
