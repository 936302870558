import first from "../../images/raipurplacementimg/first.jpg"
import second from "../../images/raipurplacementimg/second.jpg"
import third from "../../images/raipurplacementimg/third.jpg"
import fourth from "../../images/raipurplacementimg/fourth.png"
import fifth from "../../images/raipurplacementimg/fifth.png"
import six from "../../images/raipurplacementimg/six.png"

const imgData=[

    {

        img:first,

    },
    {

        img:second,

    },
    {

        img:third,

    },
    {

        img:fourth,

    },
    {

        img:fifth,

    },
    {

        img:six,

    },
    {

        img:first,

    },
    {

        img:second,

    },
    {

        img:third,

    },
    {

        img:fourth,

    },
    {

        img:fifth,

    },
    {

        img:six,

    },


]



export default imgData