import React from "react";

import { Row, Col } from "react-bootstrap";
import Diet from "../CourseOffered/Paramedical Course/Diet";
import Diploma from "../CourseOffered/Paramedical Course/Diploma";
import Ecg from "../CourseOffered/Paramedical Course/Ecg";
import Ortho from "../CourseOffered/Paramedical Course/Ortho";
import Eye from "../CourseOffered/Paramedical Course/Eye";
import Lab from "../CourseOffered/Paramedical Course/Lab";

import {
  LabCourse,
  eyeCourse,
  orthoCourse,
  dietCourse,
  ecgCourse,
  diplomaCourse,
} from "../CourseOffered/Variable";

const ParamedicalCard = () => {
  return (
    <>
      <section className="coursecard">
        <div className="container-fluid">
          <div style={{ marginBottom: "1.5rem" }}>
            <h2
              style={{
                marginTop: "2rem",
                textAlign: "center",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "42px",
                lineHeight: "51px",
              }}
            >
              Courses Offered
            </h2>

            <div className="coursecard">
              <Row className="d-flex align-items-start">
                <Col lg="4">
                  <Diet data={dietCourse} />
                </Col>
                <Col lg="4">
                  <Diploma data={diplomaCourse} />
                </Col>
                <Col lg="4">
                  <Ecg data={ecgCourse} />
                </Col>
                <Col lg="4">
                  <Eye data={eyeCourse} />
                </Col>
                <Col lg="4">
                  <Lab data={LabCourse} />
                </Col>
                <Col lg="4">
                  <Ortho data={orthoCourse} />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ParamedicalCard;
